<template>
  <main id="Media">
    <CCard>
      <CCardHeader>
        {{ $t('Navigation.Media') }}
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseFiles.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseFiles.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="info" size="sm" class="mr-2" @click="OpenMoveModal">{{ $t('Global.Move') }}</CButton>
            <CButton color="danger" size="sm" class="mr-2" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
            <CButton color="light" size="sm" @click="ChooseFiles = []">{{ $t('Global.Cancel') }}</CButton>
          </div>
        </CAlert>
      </CCardHeader>
      <CCardBody>
        <CCollapse :show="UploadArea">
          <Dropzone
              id="DropUpload"
              ref="DropUpload"
              :key="Path"
              :options="dropzoneOptions"
              :useCustomSlot="true"
              class="mb-3"
              @vdropzone-complete-multiple="MultipleUpload"
          >
            <CIcon name="cil-image-plus" class="c-icon-custom-size" size="lg" />
            <span class="d-block">{{ $t('Global.DragAndUpload') }}</span>
          </Dropzone>
        </CCollapse>
        <CRow>
          <CCol xl="2" lg="3" sm="4">
            <CButton color="success" block size="sm" class="mb-2" @click="UploadArea = !UploadArea">
              <CIcon name="cil-image-plus" size="sm" /> {{ $t('Media.Upload') }}
            </CButton>
            <CButton :color="(ChosenAll === true ? 'success' : 'secondary')" block size="sm" class="mb-2" @click="CheckAll()">
              <CIcon name="cil-check" size="sm" /> {{ $t('Media.CheckAll') }}
            </CButton>
            <CButton color="light" block size="sm" class="mb-2" @click="GetMediaList(Path)">
              <CIcon name="cil-loop-circular" size="sm" /> {{ $t('Media.Refresh') }}
            </CButton>
            <CButton v-if="AllPath.length > 1" color="danger" block size="sm" class="mb-2" @click="DeleteFolder">
              <CIcon name="cil-trash" size="sm" /> {{ $t('Media.DeleteFolder') }}
            </CButton>
            <CInput type="text" size="sm" :placeholder="$t('Media.FolderName')" v-model="FolderName" class="mb-2">
              <template #append>
                <CButton color="info" @click="CreateFolder">{{ $t('Media.CreateFolder') }}</CButton>
              </template>
            </CInput>
            <CInput type="text" size="sm" :placeholder="$t('Media.SearchInFolder')" v-model="SearchTerm" class="mb-2">
              <template #append>
                <CButton color="success" @click="GetMediaList(Path)">{{ $t('Media.Search') }}</CButton>
              </template>
            </CInput>
            <CCard id="MetaInfo" v-if="FileMeta.URL" borderColor="0">
              <CLink :href="FileMeta.URL" target="_blank">
                <template v-if="CheckContentType(FileMeta) === 'image'">
                  <CCardImg :src="FileMeta.URL" thumbnail/>
                </template>
              </CLink>
              <CCardBody class="p-3">
                <CCardText>
                  <CInput :label="$t('Media.Name')" :value="FileMeta.Name" size="sm" plaintext/>
                  <CInput :label="$t('Media.Size')" :value="FileMeta.Size" size="sm" plaintext/>
                  <CInput :label="$t('Media.ContentType')" :value="FileMeta.ContentType" size="sm" plaintext/>
                  <CInput :label="$t('Media.NewTime')" :value="FileMeta.NewTime" size="sm" plaintext/>
                  <CInput :label="$t('Media.UpdateTime')" :value="FileMeta.UpdateTime" size="sm" plaintext/>
                </CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xl="10" lg="9" sm="8">
            <CRow>
              <CCol v-if="(Path !== '')" col="4" sm="4" md="3" xl="2" class="MediaItems"
                    @click="PrevPage(AllPath.length)">
                <CCard class="border-0">
                  <CCardBody class="p-0 text-center">
                    <CIcon name="cil-action-undo" :height="60"/>
                  </CCardBody>
                  <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                    {{ $t('Global.Back') }}
                  </CCardFooter>
                </CCard>
              </CCol>
              <template v-for="(dirData, index) in DirList">
                <CCol :key="`dir-${index}`" col="4" sm="4" md="3" xl="2" class="MediaItems">
                  <CCard class="border-0">
                    <CCardBody class="p-0 text-center" @click="GoPath(dirData)">
                      <CIcon name="cil-folder-open" :height="60"/>
                    </CCardBody>
                    <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                      {{ dirData.Name }}
                    </CCardFooter>
                  </CCard>
                </CCol>
              </template>
              <template v-for="(fileData, index) in FileList">
                <CCol :key="`file-${index}`" col="4" sm="4" md="3" xl="2" class="MediaItems">
                  <CCard class="border-0">
                    <div @click="GoPath(fileData)">
                      <template v-if="CheckContentType(fileData) === 'image'">
                        <CCardImg :src="fileData.metadata.Thumbnail" thumbnail />
                      </template>
                      <template v-else>
                        <CCardBody class="p-0 text-center">
                          <CIcon name="cil-file" :height="60"/>
                        </CCardBody>
                      </template>
                    </div>
                    <label class="mb-0">
                      <input type="checkbox" :value="fileData.Path" v-model="ChooseFiles">
                    </label>
                    <CCardFooter class="text-center text-truncate px-1 py-0 pt-2 border-0">
                      {{ fileData.Name }}
                    </CCardFooter>
                  </CCard>
                </CCol>
              </template>
            </CRow>
          </CCol>
        </CRow>
        <CElementCover
            v-if="(Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner color="info"/>
        </CElementCover>
      </CCardBody>
    </CCard>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteImages' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteImages" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="MoveModal" class="ActionModel" :show.sync="MoveModal" :centered="true" :closeOnBackdrop="!Submit">
      <CButton v-if="(MoveModalPath !== '')" color="light" block size="sm" class="mb-2 text-left" @click="MoveModalPrevPage(MoveModalAllPath.length)">
        <CIcon name="cil-folder-open" class="mr-2" />...
      </CButton>
      <CButton v-for="Folder in MoveModalDirList" :key="Folder.Name" size="sm" color="light" block class="mb-2 text-left" @click="MoveModalOpenFolder(Folder.Path)">
        <CIcon name="cil-folder-open" class="mr-2" />{{ Folder.Name }}
      </CButton>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Move') }}: /{{ MoveModalPath }}</h5>
          <CButtonClose @click="MoveModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true || Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#MoveImages' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="MoveModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="MoveImages" @click="Move()" :disabled="Submit" color="success">
            {{ $t('Global.Confirm') }}{{ $t('Global.Move') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "媒體櫃"
  }
}
</route>

<script>
export default {
  name: 'Media',
  layout: 'manage',
  components: {
    Dropzone: () => import('@/plugins/dropzone')
  },
  data () {
    return {
      Loading: true,
      Submit: false,
      ChosenAll: false,
      UploadArea: false,
      AlertModel: false,
      MoveModal: false,
      FolderName: '',
      FileList: [],
      MoveModalFileList: [],
      PageToken: '',
      File: [],
      FileMeta: {},
      Upload: {
        Message: '',
        State: null
      },
      Path: '',
      MoveModalPath: '',
      AllPath: [''],
      MoveModalAllPath: [''],
      ChooseFiles: [],
      DirList: [],
      MoveModalDirList: [],
      SearchTerm: ''
    }
  },
  computed: {
    dropzoneOptions () {
      return {
        url: '/api/media/image/upload?token=' + localStorage.getItem('XX-CSRF-Token') + '&Path=' + this.Path,
        uploadMultiple: true,
        parallelUploads: 20,
        timeout: 30000,
        maxFiles: 20,
        maxFilesize: 1024 * 1024 * 3, // MB
        addRemoveLinks: true,
        headers: {
          'xx-csrf-token': localStorage.getItem('XX-CSRF-Token')
        }
      }
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        // this.GetFiles(this.Path),
        this.GetMediaList('')
      ]).then(() => {
        return true
      }).catch((err) => {
        console.log(err)
        throw err
      })
    },
    PrevPage(level) {
      if (level <= 0) return
      this.AllPath.splice((level - 1), 1)
      this.GetMediaList(this.AllPath[(level - 2)])
    },
    MoveModalPrevPage(level) {
      if (level <= 0) return
      this.MoveModalAllPath.splice((level - 1), 1)
      this.GetMoveModalList(this.MoveModalAllPath[(level - 2)])
    },
    OpenFolder(path) {
      this.AllPath.push(path)
      this.GetMediaList(path)
    },
    MoveModalOpenFolder(path) {
      this.MoveModalAllPath.push(path)
      this.GetMoveModalList(path)
    },
    GetMediaList (Path = '') {
      this.Path = Path
      this.Loading = true
      return this.GetList(Path + this.SearchTerm).then((result) => {
        this.Loading = false
        this.DirList = result.DirList
        this.FileList = result.FileList
      })
    },
    GetMoveModalList (Path = '') {
      this.MoveModalPath = Path
      this.Loading = true
      return this.GetList(Path).then((result) => {
        this.Loading = false
        this.MoveModalDirList = result.DirList
        this.MoveModalFileList = result.FileList
      })
    },
    GetList (Path = '') {
      return this.$store.dispatch('InnerRequest', {
        url: '/media/image/list',
        method: 'post',
        data: {
          Path: Path + this.SearchTerm
        }
      }).then(({ data }) => {
        return {
          DirList: (data.Data.prefixes || []).map(item => {
            return {
              Name: item.replace(Path, '').replace('/', ''),
              Path: item,
              Type: 'Dir'
            }
          }),
          FileList: (data.Data.items || []).map(item => {
            return {
              ...item,
              Name: item.name.replace(Path, '').replace('/', ''),
              Path: item.name,
              Type: 'File',
              Size: this.GetReadableFileSizeString(item.size),
              ContentType: item.contentType,
              URL: this.$store.state.user.permission.StaticDomain ? this.$store.state.user.permission.StaticDomain + item.name : item.name,
              NewTime: item.timeCreated,
              UpdateTime: item.updated
            }
          }).filter(item => item.Name !== 'STORE' && item.Path !== 'sitemap.xml')
        }
      }).catch((error) => {
        throw error
      })
    },
    async GetFiles (path = '/', pageToken = false, pageItem = 40) {
      this.Path = path
      this.Loading = true
      const storageInstance = this.$store.state.userProjectApp.storage()
      const Query = { maxResults: pageItem }
      if (pageToken) Query.pageToken = this.PageToken
      else this.ResetState()

      const FileData = await storageInstance.ref(path).list(Query)
      FileData.prefixes.forEach((el) => {
        this.DirList.push({
          Name: el.name,
          Path: el.fullPath,
          Type: 'Dir'
        })
      })

      const processes = FileData.items.map((el) => {
        return this.GetFileMeta(el)
      })
      Promise.all(processes).then((result) => {
        result.forEach((el) => {
          if(el) this.FileList.push(el)
        })
      }).catch((error) => {
        console.log(error)
      })

      this.Loading = false
      this.PageToken = FileData.nextPageToken
    },
    GetFileMeta (file) {
      const storageInstance = this.$store.state.userProjectApp.storage()
      return storageInstance.ref().child(file.fullPath).getMetadata().then((MetaData) => {
        if (file.name !== 'STORE') {
          if (this.$store.state.user.permission.StaticDomain) {
            return {
              Name: file.name,
              Path: file.fullPath,
              Type: 'File',
              Size: this.GetReadableFileSizeString(MetaData.size),
              ContentType: MetaData.contentType,
              URL: this.$store.state.user.permission.StaticDomain + file.fullPath,
              NewTime: MetaData.timeCreated,
              UpdateTime: MetaData.updated
            }
          } else {
            return storageInstance.ref().child(file.fullPath).getDownloadURL().then((URL) => {
              return {
                Name: file.name,
                Path: file.fullPath,
                Type: 'File',
                Size: this.GetReadableFileSizeString(MetaData.size),
                ContentType: MetaData.contentType,
                NewTime: MetaData.timeCreated,
                UpdateTime: MetaData.updated,
                URL
              }
            }).catch((err) => {
              throw err
            })
          }
        }
      })
    },
    async ShowFileMeta(file) {
      this.FileMeta = file
      const storageInstance = this.$store.state.userProjectApp.storage()
      if (!this.$store.state.user.permission.StaticDomain) {
        this.FileMeta.URL = await storageInstance.ref().child(file.Path).getDownloadURL()
      }
      // return this.$store.dispatch('InnerRequest', {
      //   url: '/media/image/meta',
      //   method: 'post',
      //   data: {
      //     FileName: file.Path
      //   }
      // })
    },
    GoPath(fileData) {
      if (fileData.Type === 'Dir') {
        this.OpenFolder(fileData.Path)
      } else {
        this.ShowFileMeta(fileData)
      }
    },
    /**
     * @return {string}
     */
    GetReadableFileSizeString (_Bytes) {
      let i = -1
      const byteUnits = [' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB']
      do {
        _Bytes = _Bytes / 1024
        i++
      } while (_Bytes > 1024)

      return Math.max(_Bytes, 0.1).toFixed(1) + byteUnits[i]
    },
    Delete() {
      this.Submit = true
      const storageInstance = this.$store.state.userProjectApp.storage()
      const DeleteFile = this.ChooseFiles.map(fullPath => {
        return storageInstance.ref(fullPath).delete()
      })
      return Promise.all(DeleteFile).then(() => {
        this.ResetState()
        // this.GetFiles(this.Path)
        this.GetMediaList(this.Path)
      }).catch((err) => {
        this.ResetState()
        // this.GetFiles(this.Path)
        this.GetMediaList(this.Path)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + this.$t('Message.' + err.code),
          type: (err.type ? err.type : 'error')
        })
      })
    },
    CreateFolder() {
      if (this.FolderName === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Media/PleaseEnterFolderName'),
          type: 'warn'
        })
        return false
      }
      const storageInstance = this.$store.state.userProjectApp.storage()
      return storageInstance.ref(this.Path + '/' + this.FolderName + '/STORE').putString('Dir').then(() => {
        // this.GetFiles(this.Path)
        this.GetMediaList(this.Path)
        this.FolderName = ''
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Media/CreateFolderSuccess'),
          type: 'success'
        })
      })
    },
    DeleteFolder() {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Info'),
        text: this.$t('Global.ConfirmDeleteFolder'),
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Submit'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$store.dispatch('InnerRequest', {
            url: '/media/image/delete',
            method: 'post',
            data: {
              Path: this.Path
            }
          }).then(() => {
            this.GetMediaList('')
            this.AllPath = ['']
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Success'),
              text: this.$t('Message.Media/DeleteFolderSuccess'),
              type: 'success'
            })
          })
        }
      })
    },
    CheckContentType(file) {
      switch (file.ContentType.split('/')[0]) {
        case 'image':
          return file.ContentType.split('/')[0]
        default:
          return file.ContentType.split('/')[1]
      }
    },
    MultipleUpload() {
      this.GetMediaList(this.Path)
      this.$refs.DropUpload.removeAllFiles(true)
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Success'),
        text: this.$t('Message.Media/UploadSuccess'),
        type: 'success'
      })
    },
    OpenMoveModal () {
      this.MoveModal = true
      this.GetMoveModalList('')
    },
    Move () {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/media/image/move',
        method: 'post',
        data: {
          Files: this.ChooseFiles,
          Path: this.MoveModalPath
        }
      }).then(() => {
        this.Submit = false
        this.MoveModal = false
        this.ChooseFiles = []
        this.GetMediaList(this.Path)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Media/MoveSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Submit = false
        console.log(err)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/MoveFail') + this.$t('Message.' + err.code_),
          type: (err.type ? err.type : 'error')
        })
      })
    },
    CheckAll() {
      if (this.ChosenAll) {
        this.ChosenAll = false
        this.ChooseFiles = []
      } else {
        this.ChosenAll = true
        this.ChooseFiles = this.FileList.filter(file => file.Type === 'File').map(file => file.Path)
      }
    },
    ResetState() {
      this.FileList = []
      this.DirList = []
      this.FileMeta = {}
      this.ChooseFiles = []
      this.Submit = false
      this.AlertModel = false
    }
  }
}
</script>

<style lang="scss">
@media (min-width: 1600px) {
  #Media {
    .MediaItems {
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
}

@media (min-width: 1920px) {
  #Media {
    .MediaItems {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
}

#Media {
  .MediaItems {
    cursor: pointer;
    .c-icon-custom-size {
      width: 75%;
    }
    label {
      position: absolute;
      top: 0;
      left: 0;
    }
    label > input[type='checkbox'] {
      width: 24px;
      height: 24px;
    }
    .img-thumbnail.card-img {
      height: 60px;
      object-fit: cover;
    }
  }
  #MetaInfo {
    background-color: #fafafa;
  }
  #MetaInfo {
    label {
      margin-bottom: 0;
      color: #999999;
    }
  }
  #MetaInfo {
    .form-control-plaintext {
      color: #333333;
    }
  }
  #ChooseNotice {
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    padding: 0.5rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
